import {
  Grid,
  Card,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import { GridActionsCellItem, GridOverlay } from "@mui/x-data-grid-pro";
import {
  AvailabilityResponseItem,
  S12DoctorListItem,
  DoctorAvailability,
  DeleteDoctorAvailabilityInput,
} from "@s12solutions/types";
import ProTable from "components/proTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/HighlightOff";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import notFalsy, {
  DoctorAvailabilityObj,
  convertRecurringDoctorAvailablityToEditableAvailability,
  convertDoctorAvailabilityToAddUpdateData,
  getIdsToOneSeries,
  countSeriesWithMultipleEntries,
} from "common/functions";
import dayjs from "dayjs";
import AvailabilityItemPopupV3 from "./AvailabilityItemPopupV3";
import { usePopups } from "hooks/usePopups";
import {
  EMPTY_STRING,
  EVENTUAL_CONSISTENCY_TIMEOUT,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "common/constants/common";
import {
  CONFIRM_DELETED,
  DOCTOR_DISABLED,
  UNEXPECTED_ERROR_MESSAGE,
} from "common/constants/messages";
import utc from "dayjs/plugin/utc";
import { useAPI, useAuth, useOnMount } from "hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { ContentCopy, ExpandMore } from "@mui/icons-material";
import { HtmlTooltip } from "components/tooltip";
import { HtmlBadge } from "components/badge";
import { compact } from "lodash";
dayjs.extend(utc);

interface DoctorFormProps {
  doctorId: string;
  handleCancel: () => void;
}

interface SeriesGroup {
  [seriesId: string]: DoctorAvailability[];
}
type SeriesArray = DoctorAvailability[][];

const DoctorAvailabilitySelectFormV3: React.FC<DoctorFormProps> = ({
  doctorId,
  handleCancel,
}) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];
  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }
  const {
    handleConfirmation,
    handleBannerMessage,
    handleSnackbar,
    closeSnackbar,
  } = usePopups();
  const [pageLoading, setPageLoading] = useState(false);
  const [doctorAvailabilities, setDoctorAvailabilities] = useState<
    DoctorAvailability[]
  >([]);

  const {
    trigger: loadS12Doctor,
    data,
    loading,
    error,
  } = useAPI<
    S12DoctorListItem | null,
    {
      id: string;
    }
  >({
    method: "GET",
    fieldName: "getS12Doctor",
    args: {
      id: doctorId,
    },
    manual: true,
  });

  const { trigger: deleteDoctorAvailability, loading: deleteLoadingV3 } =
    useAPI<
      AvailabilityResponseItem | null,
      { input: DeleteDoctorAvailabilityInput[] }
    >({
      method: "PUT",
      fieldName: "deleteDoctorAvailability",
      manual: true,
    });

  const [doctorAvailabilityObj, setDoctorAvailabilityObj] =
    useState<DoctorAvailabilityObj | null>(null);
  const [isEditing, setIsEditing] = useState<boolean | null>(null);
  const [isDuplicate, setIsDuplicate] = useState<boolean | null>(null);

  const [open, setOpen] = useState(false);

  useOnMount(() => {
    loadS12Doctor();
  });

  const close = () => {
    setOpen(!open);
    setPageLoading(true);
    setTimeout(() => {
      loadS12Doctor();
      setIsDuplicate(false);
      setIsEditing(false);
      setPageLoading(false);
      setDoctorAvailabilityObj(null);
    }, EVENTUAL_CONSISTENCY_TIMEOUT);
  };

  useEffect(() => {
    if (error) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [error, handleBannerMessage]);

  useEffect(() => {
    if (!loading && !deleteLoadingV3 && !pageLoading && data) {
      // setAvailabilities(compact(data.availabilities.items));
      setDoctorAvailabilities(compact(data?.doctorAvailabilities?.items));
    } else {
      // setAvailabilities([]);
      setDoctorAvailabilities([]);
    }

    return () => {
      setDoctorAvailabilities([]);
    };
  }, [data, loading, deleteLoadingV3, pageLoading]);

  /**
   * Function to filter past events based on their endDateTime.
   *
   * This function checks if the endDateTime of the given availability
   * is before the current date and time. If yes, it returns true indicating
   * that the availability is in the past; otherwise, it returns false.
   *
   * @param {Object} av - The availability object.
   * @returns {boolean} True if the availability is in the past, false otherwise.
   */
  const pastFilter = (av: any) =>
    av.endDateTime ? dayjs(av.endDateTime).isBefore(dayjs()) : false;

  /**
   * Memoized function to get upcoming events from doctor availabilities.
   *
   * This function filters the `doctorAvailabilities` array to include only those
   * availabilities that have both `startDateTime` and `endDateTime` defined,
   * and whose `endDateTime` is after the current date and time.
   *
   * @param {Array} doctorAvailabilities - The array of doctor availabilities.
   * @returns {Array} An array of upcoming events.
   */
  const upcomingEvents = useMemo(
    () =>
      doctorAvailabilities.filter(
        (val) =>
          !!val.startDateTime &&
          !!val.endDateTime &&
          dayjs(val.endDateTime).isAfter(dayjs())
      ),
    [doctorAvailabilities]
  );

  /**
   * Memoized function to get rota events from doctor availabilities.
   *
   * This function filters the `doctorAvailabilities` array to include only those
   * availabilities that have both `rotaId` and `rotaName` defined. The resulting
   * array is then sorted by `startDateTime` in descending order.
   *
   * @param {Array} doctorAvailabilities - The array of doctor availabilities.
   * @returns {Array} An array of rota events.
   */
  const rotaEvents = useMemo(
    () =>
      doctorAvailabilities
        .filter(notFalsy)
        .filter((val) => !!val.rotaId && !!val.rotaName)
        .sort(
          (a, b) =>
            dayjs(b.startDateTime).valueOf() - dayjs(a.startDateTime).valueOf()
        ),
    [doctorAvailabilities]
  );

  /**
   * Memoized function to get past events from doctor availabilities.
   *
   * This function filters the `doctorAvailabilities` array to include only those
   * availabilities that are not null and whose `endDateTime` is before the current
   * date and time. The resulting array is then sorted by `startDateTime` in descending order.
   *
   * @param {Array} doctorAvailabilities - The array of doctor availabilities.
   * @returns {Array} An array of past events.
   */
  const pastEvents = useMemo(() => {
    return doctorAvailabilities
      .filter(notFalsy)
      .filter((av) => dayjs(av.endDateTime).isBefore(dayjs()))
      .sort(
        (a, b) =>
          dayjs(b.startDateTime).valueOf() - dayjs(a.startDateTime).valueOf()
      );
  }, [doctorAvailabilities]);

  /**
   * Memoized function to group and sort current series of doctor availabilities.
   *
   * This function groups the `doctorAvailabilities` array by `seriesId` and filters out
   * availabilities that are null or in the past. The resulting groups are then sorted
   * by the `startDateTime` of the first availability in each group.
   *
   * @param {Array} doctorAvailabilities - The array of doctor availabilities.
   * @returns {Array} An array of grouped and sorted series of doctor availabilities.
   */
  const currentSeriesV3 = useMemo(() => {
    const seriesGroups = doctorAvailabilities.reduce<SeriesGroup>((acc, av) => {
      if (notFalsy(av) && !pastFilter(av)) {
        acc[av.seriesId] = acc[av.seriesId] || [];
        acc[av.seriesId].push(av);
      }
      return acc;
    }, {});

    return Object.values(seriesGroups).sort(
      (groupA: DoctorAvailability[], groupB: DoctorAvailability[]) => {
        const startA = groupA.length
          ? dayjs(groupA[0].startDateTime).valueOf()
          : 0;
        const startB = groupB.length
          ? dayjs(groupB[0].startDateTime).valueOf()
          : 0;
        return startA - startB;
      }
    );
  }, [doctorAvailabilities]);

  /**
   * Memoized function to flatten and transform series of doctor availabilities.
   *
   * This function processes `currentSeriesV3` to:
   * - Skip series with 0 or 1 availability.
   * - Sort each series by `startDateTime`.
   * - Extract unique days of the week from `startDateTime`.
   * - Return an array of objects representing the flattened series with details:
   *   - `id`, `seriesId`, `startDateTime`, `endDateTime`, `endDateTimeForDisplay`, `daysOfTheWeek`, `notes`.
   *
   * @param {Array} currentSeriesV3 - The array of arrays representing series of doctor availabilities.
   * @returns {Array} An array of flattened series objects.
   */
  const flattenedSeries = useMemo(() => {
    return currentSeriesV3.flatMap((serie) => {
      if (serie.length === 0 || serie.length === 1) {
        return [];
      }

      const sortedSerie = serie.sort(
        (a: DoctorAvailability, b: DoctorAvailability) =>
          new Date(a.startDateTime).valueOf() -
          new Date(b.startDateTime).valueOf()
      );

      const firstAvailability = sortedSerie[0];
      const lastAvailability = sortedSerie[sortedSerie.length - 1];

      const daysOfTheWeek = sortedSerie.map((av) =>
        dayjs(av.startDateTime).format("dddd")
      );
      const uniqueDays = [...new Set(daysOfTheWeek)];
      let numberOfWeeks = 0;
      if (uniqueDays.length === uniqueDays.length) {
        const daysCount = uniqueDays.map((day) => ({
          day,
          count: daysOfTheWeek.filter((d) => d === day).length,
        }));
        numberOfWeeks = Math.min(...daysCount.map((dc) => dc.count));
      }

      return [
        {
          id: sortedSerie[0].seriesId,
          seriesId: firstAvailability.seriesId,
          startDateTime: firstAvailability.startDateTime,
          endDateTime: firstAvailability.endDateTime,
          endDateTimeForDisplay: lastAvailability.endDateTime,
          daysOfTheWeek: uniqueDays.join(", "),
          numberOfWeeks: numberOfWeeks,
          notes: `Serie: ${sortedSerie.length} availabilities`,
        },
      ];
    });
  }, [currentSeriesV3]);

  const AvailabilityListItem = (
    doctorAvailabilities: DoctorAvailability & { date?: Date }
  ) => {
    const startDate = dayjs(doctorAvailabilities.startDateTime).format(
      "MMMM DD, YYYY"
    );
    const startTime = dayjs(doctorAvailabilities.startDateTime).format("HH:mm");
    const endDate = dayjs(doctorAvailabilities.endDateTime).format(
      "MMMM DD, YYYY"
    );
    const endTime = dayjs(doctorAvailabilities.endDateTime).format("HH:mm");

    const eventTime = `${startDate} ${startTime} - ${endDate} ${endTime}`;

    return (
      <>
        <h5 className="font-weight-normal mb-0">{eventTime}</h5>
        <p className="mb-0">
          <small>{doctorAvailabilities.notes}</small>
        </p>
      </>
    );
  };
  const handleDelete = useCallback(
    (ids: string[]) => {
      // Accept an array of IDs
      handleConfirmation(CONFIRM_DELETED, () => {
        // Convert the IDs into the format required by the API
        const deleteInputs = ids.map((id) => ({ id }));

        // Call the delete API
        deleteDoctorAvailability({ input: deleteInputs })
          .then((result) => {
            if (result && result.data && Array.isArray(result.data)) {
              handleBannerMessage(
                TYPE_SUCCESS,
                `${result.data.length} availabilities deleted`
              );
              setPageLoading(true);
              setTimeout(() => {
                loadS12Doctor();
                setPageLoading(false);
              }, EVENTUAL_CONSISTENCY_TIMEOUT);
            } else {
              handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
            }
          })
          .catch(() => {
            handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
          });
      });
    },
    [
      deleteDoctorAvailability,
      handleBannerMessage,
      handleConfirmation,
      loadS12Doctor,
    ]
  );

  useEffect(() => {
    if (data?.disabled) {
      handleSnackbar(TYPE_ERROR, DOCTOR_DISABLED, -1, true);
    }
    return () => {
      closeSnackbar();
    };
  }, [closeSnackbar, handleBannerMessage, handleSnackbar, data?.disabled]);

  return (
    <>
      {isEditing !== null && data && (
        <AvailabilityItemPopupV3
          open={open}
          close={close}
          user={data}
          isEditing={isEditing}
          isDuplicate={isDuplicate}
          doctorAvailabilityObj={doctorAvailabilityObj}
          refetch={loadS12Doctor}
        />
      )}
      <Grid
        xs={12}
        m={1}
        p={2}
        component={Card}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <Typography variant="h1">Manage Availability V3</Typography>
              </Grid>
              {data?.disabled && (
                <Grid item>
                  <Chip
                    style={{ borderRadius: ".3rem" }}
                    label="Disabled"
                    color="error"
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item container sm={6} spacing={3}>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">GMC</Typography>
                    <Typography variant="h3">
                      {data?.id ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Name</Typography>
                    <Typography variant="h3">
                      {data?.name ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container sm={6} spacing={3}>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Email</Typography>
                    <Typography variant="h3">
                      {data?.email ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Phone</Typography>
                    <Typography variant="h3">
                      {data?.phone ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item container sm={3} spacing={3}>
                <Grid item>
                  <Button
                    id="addAvailability"
                    data-test-id="addAvailability"
                    variant="contained"
                    color={"primary"}
                    size="large"
                    onClick={() => {
                      setOpen(true);
                      setIsEditing(false);
                      setIsDuplicate(false);
                    }}
                    disabled={readOnly}
                  >
                    Add Availability
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="cancelAvailability"
                    data-test-id="cancelAvailability"
                    variant="outlined"
                    color={"primary"}
                    size="large"
                    onClick={handleCancel}
                    disabled={readOnly}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Rota Availabilities
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HtmlBadge counter={rotaEvents.length} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProTable
                      noResultsOverlay={() => (
                        <GridOverlay>
                          <Typography>- No availabilities - </Typography>
                        </GridOverlay>
                      )}
                      loading={loading || deleteLoadingV3 || pageLoading}
                      rows={rotaEvents || []}
                      columns={[
                        {
                          field: "actions",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Edit" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"editRotaAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"editRotaAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <EditIcon
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="editSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(true);
                                  setIsDuplicate(false);
                                  setDoctorAvailabilityObj(
                                    convertDoctorAvailabilityToAddUpdateData(
                                      params.row,
                                      true,
                                      false
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions3",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Duplicate" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"duplicateRotaAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"duplicateRotaAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <ContentCopy
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="duplicateSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(false);
                                  setIsDuplicate(true);
                                  setDoctorAvailabilityObj(
                                    convertDoctorAvailabilityToAddUpdateData(
                                      params.row,
                                      false,
                                      true
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions2",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Delete" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"deleteRotaAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"deleteRotaAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <DeleteIcon
                                    color={readOnly ? "disabled" : TYPE_ERROR}
                                  />
                                }
                                label="deleteSchedule"
                                onClick={() => handleDelete([params.row.id])}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          headerName: "Availability Summary",
                          field: "rotaAvailability",
                          valueGetter: (params) => ({
                            startDateTime: params.row.startDateTime,
                            endDateTime: params.row.endDateTime,
                          }),
                          renderCell: (params: any) =>
                            AvailabilityListItem({
                              ...params.row,
                              date: new Date(params.row.startDateTime),
                            }),
                          sortComparator: (a, b) =>
                            dayjs(a.startDateTime).valueOf() -
                            dayjs(b.startDateTime).valueOf(),
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Rota Name",
                          field: "rotaName",
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Notes",
                          field: "rotaNotes",
                          valueGetter: (params) => params.row.notes,
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      onClickRowData={(event: any) => {
                        if (!readOnly) {
                          setOpen(true);
                          setIsEditing(true);
                          setDoctorAvailabilityObj(
                            convertDoctorAvailabilityToAddUpdateData(
                              event,
                              true,
                              false
                            )
                          );
                        }
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Current and Upcoming Availabilities
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HtmlBadge counter={upcomingEvents.length} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProTable
                      noResultsOverlay={() => (
                        <GridOverlay>
                          <Typography>- No availabilities - </Typography>
                        </GridOverlay>
                      )}
                      loading={loading || deleteLoadingV3 || pageLoading}
                      rows={upcomingEvents || []}
                      columns={[
                        {
                          field: "actions",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Edit" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"editCurrentAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"editCurrentAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <EditIcon
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="editSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(true);
                                  setIsDuplicate(false);
                                  setDoctorAvailabilityObj(
                                    convertDoctorAvailabilityToAddUpdateData(
                                      params.row,
                                      true,
                                      false
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions3",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Duplicate" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"duplicateCurrentAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"duplicateCurrentAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <ContentCopy
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="duplicateSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(false);
                                  setIsDuplicate(true);
                                  setDoctorAvailabilityObj(
                                    convertDoctorAvailabilityToAddUpdateData(
                                      params.row,
                                      false,
                                      true
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions2",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Delete" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"deleteCurrentAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"deleteCurrentAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <DeleteIcon
                                    color={readOnly ? "disabled" : TYPE_ERROR}
                                  />
                                }
                                label="deleteSchedule"
                                onClick={() => handleDelete([params.row.id])}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          headerName: "Availability Summary",
                          field: "currentAvailability",
                          valueGetter: (params) => ({
                            startDateTime: params.row.startDateTime,
                            endDateTime: params.row.endDateTime,
                          }),
                          renderCell: (params: any) =>
                            AvailabilityListItem({
                              ...params.row,
                              date: new Date(params.row.startDateTime),
                            }),
                          sortComparator: (a, b) =>
                            dayjs(a.startDateTime).valueOf() -
                            dayjs(b.startDateTime).valueOf(),
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Notes",
                          field: "currentNotes",
                          valueGetter: (params) => params.row.notes,
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      onClickRowData={(event: any) => {
                        if (!readOnly) {
                          setOpen(true);
                          setIsEditing(true);
                          setDoctorAvailabilityObj(
                            convertDoctorAvailabilityToAddUpdateData(
                              event,
                              true,
                              false
                            )
                          );
                        }
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Recurring Availabilities
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HtmlBadge
                          counter={countSeriesWithMultipleEntries(
                            currentSeriesV3
                          )}
                        />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProTable
                      noResultsOverlay={() => (
                        <GridOverlay>
                          <Typography>- No availabilities - </Typography>
                        </GridOverlay>
                      )}
                      loading={loading || deleteLoadingV3 || pageLoading}
                      rows={flattenedSeries || []}
                      columns={[
                        {
                          field: "actions",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Edit" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"editRecurringDoctorAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"editRecurringDoctorAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <EditIcon
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="editSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(true);
                                  setDoctorAvailabilityObj(
                                    convertRecurringDoctorAvailablityToEditableAvailability(
                                      params.row,
                                      currentSeriesV3,
                                      true
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions3",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Duplicate" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"duplicateRecurringAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"duplicateRecurringAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <ContentCopy
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="duplicateSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(true);
                                  setDoctorAvailabilityObj(
                                    convertRecurringDoctorAvailablityToEditableAvailability(
                                      params.row,
                                      currentSeriesV3,
                                      false
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions2",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Delete">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"deleteRecurringAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"deleteRecurringAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <DeleteIcon
                                    color={readOnly ? "disabled" : TYPE_ERROR}
                                  />
                                }
                                label="deleteSchedule"
                                onClick={() => {
                                  const idsToDelete = getIdsToOneSeries(
                                    params.row,
                                    currentSeriesV3
                                  );
                                  handleDelete(idsToDelete);
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          headerName: "Availability Summary",
                          field: "recurringAvailability",
                          renderCell: (params: any) => (
                            <div>
                              {`Start: ${dayjs(params.row.startDateTime).format(
                                "YYYY-MM-DD"
                              )}`}
                              <br />
                              {`End: ${dayjs(
                                params.row.endDateTimeForDisplay
                              ).format("YYYY-MM-DD")}`}
                            </div>
                          ),
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Informations",
                          field: "informations",
                          renderCell: (params: any) => (
                            <div>
                              {`${params.row.notes}`}
                              <br />
                              {`number Of Weeks: ${params.row.numberOfWeeks} | Days: ${params.row.daysOfTheWeek}`}
                            </div>
                          ),
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      onClickRowData={(event: any) => {
                        if (!readOnly) {
                          setOpen(true);
                          setIsEditing(true);
                          setDoctorAvailabilityObj(
                            convertDoctorAvailabilityToAddUpdateData(
                              event,
                              true,
                              false
                            )
                          );
                        }
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Past Availabilities
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HtmlBadge counter={pastEvents.length} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProTable
                      noResultsOverlay={() => (
                        <GridOverlay>
                          <Typography>- No availabilities - </Typography>
                        </GridOverlay>
                      )}
                      loading={loading || deleteLoadingV3 || pageLoading}
                      rows={pastEvents || []}
                      columns={[
                        {
                          headerName: "Availability Summary",
                          field: "pastAvailability",
                          valueGetter: (params) => ({
                            startDateTime: params.row.startDateTime,
                            endDateTime: params.row.endDateTime,
                          }),
                          renderCell: (params: any) =>
                            AvailabilityListItem({
                              ...params.row,
                              date: new Date(params.row.startDateTime),
                            }),
                          sortComparator: (a, b) =>
                            dayjs(a.startDateTime).valueOf() -
                            dayjs(b.startDateTime).valueOf(),
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Notes",
                          field: "pastNotes",
                          valueGetter: (params) => params.row.notes,
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      onClickRowData={(event: any) => {
                        if (!readOnly) {
                          setOpen(true);
                          setIsEditing(true);
                          setDoctorAvailabilityObj(
                            convertDoctorAvailabilityToAddUpdateData(
                              event,
                              true,
                              false
                            )
                          );
                        }
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorAvailabilitySelectFormV3;
